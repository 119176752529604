<template>
  <FocusTrap>
    <div class="card" id="receipt_card">

    <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">

      <h5 v-if="voucher.id == 0" class="card-title">Receipt Creation</h5>
      <h5 v-if="voucher.id > 0" class="card-title"> Vch No: {{voucher.vch_no}}  Receipt Updation</h5>

      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
          <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
        </div>
      </div>
    </div>



    <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Received Ledger</label>
            <select id="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher" v-model="voucher.ledger_id" >
              <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                {{ ledger.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-7">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Date</label>
            <input id="txtDate" type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
          </div>
        </div>
      </div>


      <div class="row">
        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th >Particulars</th>
              <th >Description</th>
              <th style="width:150px; text-align: right;">Amount</th>
              <th style="width: 30px;">Action</th>
            </tr>

            </thead>
            <tbody >
              <tr v-for="(detail,index) in voucher.list" >
                <td style="text-align: center;padding: 0px;"> {{index + 1}} </td>
                <td style="padding: 0px;">
                  <select class="form-control" v-if="detail" v-model="detail.ledger_id" >
                    <option v-for="ledger in deailledgers" v-bind:value="ledger.id">
                      {{ ledger.name }}
                    </option>
                  </select>
                </td>
                <td style="padding: 0px;">
                  <input type="text" class="form-control" placeholder="Description" maxlength="100"  v-if="detail" v-model="detail.remarks" />
                </td>
                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.debit" @change="sumRowAmountTotal" />
                </td>
                <td style="padding: 0px;text-align: right">
                  <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                </td>
              </tr>
            </tbody>
            <tfoot>
            <tr>
              <td style="padding: 0px;">
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>

              <td class="text-right" >&#8377;<span style="padding-right: 15px;">{{ indianFormat(rowAmountTotal) }}</span></td>

              <td></td>


            </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div class="row align-items-end">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">
        </div>

        <div class="col-md-3 text-right">
          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'ReceiptForm',
    store,
    components:{

    },
    data () {
      return {
        readonly: false,
        ledgers:[],
        deailledgers:[],
        rowAmountTotal: 0.0,
        voucher: JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":1,"finyear":2000,"vch_type":1,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","cmp_id":1,"ledger":{"id":1,"name":""},"list":[]}')
      }
    },
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":1,"finyear":2000,"vch_type":1,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","cmp_id":1,"ledger":{"id":1,"name":""},"list":[]}')
      }
    },
    beforeMount () {
      this.voucher = this.myvoucher;
      this.sumRowAmountTotal();
    },
    created () {
      this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      this.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      const self = this;
      self.loadData();

      if( self.$data.voucher.doc_date == '0001-01-01' || self.$data.voucher.doc_date == '2000-01-01' ){
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      }

      $('#cmbledger').focus();
    },
    methods:{
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      closeThis(){
        this.$emit('receipt_window_closed');
        if(this.voucher.id > 1){
          this.$modal.hide('voucher-window');
          this.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":1,"finyear":2000,"vch_type":1,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","cmp_id":1,"ledger":{"id":1,"name":""},"list":[]}');
        }else{
          this.$router.push("/")
        }

      },
      addRow(){
        const self = this;
        self.$data.voucher.list.push(JSON.parse('{"idx":0,"adjust_type":0,"ledger":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"debit":0,"credit":0,"remarks":""}'));

        setTimeout(function(){
          $("tbody>tr").last().find("td:eq(1) select").focus();
        }, 99);
      },
      sumRowAmountTotal() {
        const self = this;

        self.$data.rowAmountTotal = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowAmountTotal += parseFloat(detail.debit);
        });
      },
      clear(){
        const self = this;
        self.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":1,"finyear":2000,"vch_type":1,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","cmp_id":1,"ledger":{"id":1,"name":""},"list":[]}');
        self.$data.voucher.list = [];
        self.$data.rowAmountTotal = 0.0;
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

      },
      loadData() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/cash_bank_ledgers`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if(_.isArray(resp.data)){
              self.$data.ledgers = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });


        //Fetch Detail Ledgers
        self.$data.deailledgers = [];

        //`${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/groups/2,3,4,6,7,8,17,19,20,21,25,26,28,29,30,31,34,35,38,45/`
        fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/party_ledgers`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            self.$data.deailledgers = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.list.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      saveVoucher(){
        const self = this;

        if(self.$data.voucher.ledger_id <= 1) {
          alert("Invalid From Ledger");
          return;
        }else if(self.$data.voucher.list.length == 0 ) {
          alert("Invalid Breakups");
          return;
        }else {
          self.$data.voucher.list.forEach(function (detail) {
            if(detail.ledger_id <= 1 ) {
              alert("Invalid Breakup Ledger");
              return;
            }else if( (parseFloat(detail.debit) + parseFloat(detail.credit)) <= 0) {
              alert("Invalid Amount")
              return;
            }
          });
        }



        let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher));

        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.cmp_id = self.$store.state.user.company.id;
        myvoucher.brn_id = self.$store.state.user.branch.id;
        myvoucher.vch_type = 101;
        myvoucher.iscredit = true;
        myvoucher.type = 1; //Credit Transaction
        myvoucher.ledger_id = parseInt(myvoucher.ledger_id);
        myvoucher.doc_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');
        myvoucher.ref_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');
        myvoucher.amount =  parseFloat(self.$data.rowAmountTotal);

        myvoucher.list.forEach(function (detail){
          detail.ledger_id = parseInt(detail.ledger_id);
          detail.debit = parseFloat(detail.debit);
          detail.credit = parseFloat(detail.credit);
        });

        const requestOptions = {
          method:  ((myvoucher.id == 0) ? 'POST' : 'PUT'),
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        };


        fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevoucher`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ type: 'success', text: resp.msg, onClose: () => { $('#cmbledger').focus() }, timer: 1500 });
            self.clear();
            self.$emit('receipt_updated');
          } else {
            swal({
              title: "Oops", text: resp.msg, type: "error", onClose: () => {
                $('#cmbledger').focus()
              }, timer: 3000
            });
          }
        }).catch(function (err) {
          swal({
            title: "Oops", text: err.toString(), type: "error", onClose: () => {
              $('#cmbledger').focus()
            }, timer: 3000
          });
        });

      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
